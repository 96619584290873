export class EventTypeEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.groupName = data?.group_name;
    this.groupCode = data?.group_code;
    this.inPortal = data?.in_portal;
    this.pmDisplay = data?.pm_display;
    this.amDisplay = data?.am_display;
    this.schoolOffCheck = data?.school_off_check;
    this.schoolCheck = data?.school_check;
    this.supervisoroffchecks = data?.supervisor_off_check;
    this.supervisorchecks = data?.supervisor_check;
    this.busoffchecks = data?.bus_off_check;
    this.busCheck = data?.bus_check;
    this.campusStopCheck = data?.campus_stop_check;
    this.showAllStops = data?.show_all_stops;
    this.restrictmultiple = data?.one_student_one_route;
    this.routeCount = data?.route_count;
    this.availableRoute = data?.available_route;
    this.supervisorCheck = data?.supervisor_check;
    this.allDay = data?.all_day;
  }
}

export class RouteEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.name = data?.name;
    this.eventName = data?.name;
    this.routeGroupId = data?.route_group?.pk;
    this.routeGroup = data?.route_group?.group_name || data?.route_group;
    this.transportCompanyId = data?.transport_company?.pk;
    this.transportCompany = data?.transport_company?.name || data?.transport_company;
    this.routeType = data?.route_type;
    this.routeNumber = data?.route_number ?? '';
    this.route_name = data?.name;
    this.capacity = data?.capacity;
    this.activeInSmartRoute = data?.active_in_smart_route;
    this.active = data?.is_active;
    this.approveNonAllocated = data?.approve_non_allocated;
    this.eventcolor = data?.route_color;
    this.stoplist = data?.stoplist;
    this.timezone = data?.timezone;
    this.gpsscanningmode = data?.gps_interval;
    this.estimatedStopTime = data?.estimated_stop_time;
    this.groupoff = data?.allow_groupoff;
    this.iniframe = data?.show_in_iframe;
    this.specialConsideration = data?.special_consideration;
    this.todayRouteStatus = data?.today_route_status?.replace('_', ' ');
    this.isMultipleDriverLogin = data?.is_multiple_driver_login;
  }
}

export class RouteEventsEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.date = data?.route_date;
    this.route_name = data?.route_name;
    this.eventId = data?.route?.pk;
    this.eventColor = data?.route?.route_color;
    this.eventName = data?.route?.name;
    this.groupName = data?.route?.route_group?.group_name;
    this.groupId = data?.route?.route_group?.pk;
    this.routeType = data?.route?.route_type;
    this.isMultipleDriverLogin = data?.route?.is_multiple_driver_login;
    this.schoolCheck = data?.school_check;
    this.supervisorCheck = data?.supervisor_check;
    this.busCheck = data?.transport_medium_check;
    this.supervisor_check_datetime = data?.supervisor_check_datetime;
    this.supervisor_check_off_datetime = data?.supervisor_check_off_datetime;
    this.transport_medium_check_datetime = data?.transport_medium_check_datetime;
    this.school_check_datetime = data?.school_check_datetime;
    this.school_check_off_datetime = data?.school_check_off_datetime;
    this.driver_login_datetime = data?.driver_login_datetime;
    this.teacher = data?.teacher;
    this.driver = data?.driver;
    this.supervisor = data?.supervisor;
  }
}
export class RouteStopsEntity {
  constructor(data, userPreferenceData) {
    this.pk = data?.stop?.pk;
    this.name = data?.stop?.name;
    this.routeId = data?.route?.pk;
    this.routeName = data?.route?.name;
    this.routeColor = data?.route?.route_color;
    this.sequence = data?.sequence;
    this.address = data?.stop?.address;
    this.duration = data?.duration !== null ? data?.duration : userPreferenceData?.stopDurationDefault || null;
    this.arrivalTime = data?.expected_arrival_time;
    this.departureTime = data?.expected_departure_time;
    this.mappingId = data?.pk;
    this.latLong = data?.stop?.lat_long?.coordinates;
    this.latitude = data?.stop?.lat_long?.coordinates[0];
    this.longitude = data?.stop?.lat_long?.coordinates[1];
    this.homeCampusId = data?.stop?.home_campus_pk;
    this.typeId = 'MARKER';
    this.sequence = data?.sequence;
    this.zonePk = data?.stop?.zone?.pk ?? null;
    this.zoneName = data?.stop?.zone?.name ?? '';
    this.zoneColour = data?.stop?.zone?.colour;
    this.geofenceCoordinates = data?.stop?.geofence_coordinates;
    this.geofenceSize = data?.stop?.geofence_size;
    this.driverLoginTime = data?.driver_login_time;
  }
}

export const mapToSnakeCaseForCloneRoute = data => {
  return {
    name: data.name,
    route_group: { pk: data.routeGroupId.pk, group_name: data.routeGroup },
    transport_company: { pk: data.transportCompanyId },
    route_type: data.routeType,
    route_number: data.routeNumber,
    gps_interval: data.gpsscanningmode,
    route_color: data.eventcolor || '',
    capacity: parseInt(data.capacity) || 0,
    estimated_stop_time: data.estimatedStopTime,
    allow_groupoff: data.groupoff,
    active_in_smart_route: data.activeInSmartRoute,
    show_in_iframe: data.iniframe,
    is_active: data.active,
    approve_non_allocated: data.approveNonAllocated,
    is_clone: data.is_clone,
    old_route: data.pk,
    is_multiple_driver_login: data.isMultipleDriverLogin,
    // timezone: data.timezone,
  };
};

export const mapToSnakeCaseForSkipAlertRoute = data => {
  return {
    extra_data: {
      skip_days: data.skipDate,
    },
  };
};

export const mapToSnakeCaseRoute = data => {
  return {
    name: data.name,
    route_group: { pk: data.routeGroupId.pk, group_name: data.routeGroup },
    transport_company: { pk: data.transportCompanyId },
    route_type: data.routeType,
    route_number: data.routeNumber,
    gps_interval: data.gpsscanningmode,
    route_color: data.eventcolor || '',
    capacity: parseInt(data.capacity) || 0,
    estimated_stop_time: data.estimatedStopTime,
    allow_groupoff: data.groupoff,
    active_in_smart_route: data.activeInSmartRoute,
    show_in_iframe: data.iniframe,
    is_active: data.active,
    approve_non_allocated: data.approveNonAllocated,
    is_clone: data.is_clone,
    special_consideration: data.specialConsideration,
    smart_route_status: data.smart_route_status,
    is_multiple_driver_login: data.isMultipleDriverLogin,
    // timezone: data.timezone,
  };
};

export class RolesEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.name = data?.name;
    this.organisationId = data?.organisation_id;
    this.isActive = data?.is_active;
    this.visible = data?.extra_data?.visible;
  }
}
export class StopsEntity {
  constructor(data) {
    this.pk = data.pk ? data.pk : '';
    this.name = data.name;
    this.address = data.address;
    this.note = data.note;
    this.zoneId = data.zone?.pk || data?.zone_id || null;
    this.zone = data.zone?.name;
    this.zoneColour = data?.zone?.colour || data?.zone__colour;
    this.longitude = data?.longitude || data?.lat_long?.coordinates[1];
    this.latitude = data?.latitude || data?.lat_long?.coordinates[0];
    this.waypoint = data?.waypoint;
    this.typeId = 'STOPS';
    this.geofenceSize = data.geofence_size;
    this.activeInSmartRoute = data.active_in_smart_route;
    this.topLeft = data.geofence_coordinates?.coordinates?.flat()[0];
    this.topRight = data.geofence_coordinates?.coordinates?.flat()[1];
    this.bottomLeft = data.geofence_coordinates?.coordinates?.flat()[2];
    this.bottomRight = data.geofence_coordinates?.coordinates?.flat()[3];
    this.note = data.notes;
    this.homeCampusId = data.home_campus_pk || data?.stop?.home_campus_pk;
    this.homeCampus = data.home_campus_name;
    this.disableRowExpandable = !data?.route_stop_mapping;
  }
}
export class CampusEntity {
  constructor(data) {
    this.pk = data?.pk;
    this.name = data?.name;
    this.address = data?.address;
    this.registeredAddress = data?.registered_address;
    this.abn = data?.abn;
    this.stop = data?.stop?.name;
    this.stopId = data?.stop?.pk;
    this.contactNumber =
      data.contact_number?.length > 0
        ? {
            dialCode: data?.contact_number?.split(' ')[0].replace('+', ''),
            number: data?.contact_number?.replace(/\s/g, '').replace(`+`, '').replace('-', ''),
            countryCode: data?.country_code?.toLowerCase(),
            phone: data?.contact_number,
          }
        : { dialCode: '61', number: '61', phone: '' };
  }
}

export class StudentEntity {
  constructor(data) {
    this.pk = data.pk;
    this.firstName = data.first_name ? data.first_name : data.student?.first_name;
    this.lastName = data.last_name ? data.last_name : data.student?.last_name;
    this.fullName = data.first_name
      ? data.first_name + ' ' + data.last_name
      : data.student?.first_name + ' ' + data.student?.last_name;
    this.cardNumber = data.card_number;
    this.studentCode = data.student_code;
    this.campus = data.campus?.name || '';
    this.campusId = data.campus?.pk || undefined;
    this.campusStop = data.campus?.stop?.name || '';
    this.campusStopId = data.campus?.stop?.pk || undefined;
    this.grade = data.grade;
    this.address = data.address;
    this.bookingOption = data.booking_option;
    this.paymentOption = data.payment_option;
    this.activeInSmartRoute = data.active_in_smart_route;
    this.isActive = data.is_active;
    this.studentType = data.student_type;
    this.studentText = data.student_text;
    this.suspended = data.suspended;
    this.custody = data.custody;
    this.medicalFlag = data.medical_flag;
    this.specialConsideration = data.special_consideration;
    this.paymentFrequency = data.payment_frequency;
    this.ignoreOffStop = data.ignore_off_stop;
    this.dob = data.dob;
    this.gradeCorrection = data.grade_correction;
    this.currentAdmissionGrade = data.current_admission_grade;
    this.currentAdmissionyear = data.current_admission_year;
    this.accountValue = data.account_value;
    this.fixedFare = data.fixed_fare;
    this.discountPercentage = data.discount_percentage;
    this.notes = data.notes;
    this.latitude = data?.address_lat_long?.coordinates?.[0];
    this.longitude = data?.address_lat_long?.coordinates?.[1];
    this.typeId = 'STUDENTMARKER';
    this.disableRowExpandable = !data?.student_parent_mapping;
  }
}
